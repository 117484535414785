import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import SbEditable from "storyblok-react"
import useStoryblok from "../utils/storyblok"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import DynamicComponent from "../components/DynamicComponent"
import Footer from "../components/Footer"
import AppFeatures from "../components/features/AppFeatures"

const About = ({ data, location }) => {
  let story = data.storyblokEntry
  story = useStoryblok(story, location)

  const components = story.content.body.map(blok => {
    return <DynamicComponent blok={blok} key={blok._uid} />
  })

  return (
    <Layout>
      <Seo title="About shopsmart" pageName="about" />
      <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h1 class="sr-only">About Shopsmart. The only wallet you need.</h1>
            <h2>About Shopsmart</h2>
          </div>
          <div className="mt-10 mx-auto grid gap-1">{components}</div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default About

export const query = graphql`
  query AboutPageQuery {
    storyblokEntry(full_slug: { eq: "about" }) {
      content
      name
    }
  }
`
