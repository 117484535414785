import React from "react"
import {
  InboxIcon,
  SparklesIcon,
  ShoppingCartIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline"
import AppLibrary from "../../images/card_list.png"
import AppSearch from "../../images/shopsmart_list.png"

const AppFeatures = () => (
  <div className="relative bg-white pt-16 pb-32 overflow-hidden">
    <div className="rounded-md p-4 mb-12 ml-4 mr-4">
      <div className="flex">
        {/* <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div> */}
        {/* <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">
            A new version of our app is coming soon!
          </p>
          <p className="mt-3 text-sm md:mt-0 md:ml-6">
            <a
              href="https://www.shopsmart.com.au/articles/a-new-beginning/"
              className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
            >
              Details <span aria-hidden="true">&rarr;</span>
            </a>
          </p>
        </div> */}
      </div>
    </div>
    <div className="relative">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div>
              <span className="h-12 w-12 rounded-md flex items-center justify-center bg-red-default">
                <ShoppingCartIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
            </div>
            <div className="mt-6">
              <h2>Keep your favourite cards at your finger tips</h2>
              <p className="mt-4 text-lg text-gray-500">
                Store your favourite cards in one place. Say goodbye to your
                bulky wallet. Use the Shopsmart loyalty card e-wallet to store
                your favourite cards.
              </p>
              <div className="mt-6">
                <a
                  href="https://www.shopsmart.com.au"
                  className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-default hover:bg-red-700"
                >
                  Get the Shopsmart app now!
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-200 pt-6"></div>
        </div>
        <div className="mt-12 sm:mt-16 lg:mt-0">
          <div className="pl-4 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img
              width="320"
              className="mx-auto rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
              src={AppLibrary}
              alt="Shopsmart app - Smart Wallet"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="mt-24">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
          <div>
            <div>
              <span className="h-12 w-12 rounded-md flex items-center justify-center  bg-red-default">
                <ShoppingCartIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
            </div>
            <div className="mt-6">
              <h2>Browse for your favourite cards</h2>
              <p className="mt-4 text-lg text-gray-500">
                Our extensive library of cards gives you plenty of options to
                earn rewards. You can browse for your favourite cards quickly
                and easily. KMart, OfficeWorks, Target and many many more.
              </p>
              <div className="mt-6">
                <a
                  href="https://www.shopsmart.com.au"
                  className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-default hover:bg-red-700"
                >
                  Download the Shopsmart app and start getting rewarded!
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
          <div className="pr-4 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img
              width="320"
              className="mx-auto rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
              src={AppSearch}
              alt="Search the Shopsmart card library"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default AppFeatures
